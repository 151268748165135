import React from 'react'
import classNamesBind from 'classnames/bind'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  width?: number
  discType?: string
  white?: boolean
}

export default class LogoSVG extends React.Component<OwnProps> {
  render() {
    const { width, discType, white } = this.props
    const styles = {
      width: width ? `${width}px` : '100%',
    }

    return (
      <div style={styles}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343.73 85.37">
          <defs>
            <clipPath id="clip-path">
              <rect
                className={classNames('cls-1', { discType })}
                x="369.75"
                y="149.25"
                width="350.33"
                height="429.52"
              />
            </clipPath>
            <clipPath id="clip-path-2">
              <rect
                className={classNames('cls-1', { discType })}
                x="-15.8"
                y="149.25"
                width="350.33"
                height="429.52"
              />
            </clipPath>
          </defs>
          <title>Crystal Logo</title>
          <g id="Logo">
            <path
              className={classNames('cls-2', discType)}
              d="M337.75,56.28a36.31,36.31,0,1,0-57.29,4.37L272.53,72.7l35-.07h0A36.3,36.3,0,0,0,337.75,56.28Z"
            />
            <path
              className={classNames('cls-2', discType)}
              d="M43.34,57.45a2.23,2.23,0,0,1,1.7.73l4.82,5.13A22.83,22.83,0,0,1,41,70.19a30.72,30.72,0,0,1-12.57,2.35A30.07,30.07,0,0,1,16.59,70.3,25.73,25.73,0,0,1,7.65,64,27.83,27.83,0,0,1,2,54.53a35.25,35.25,0,0,1-2-12A35.57,35.57,0,0,1,1,34.2a29.3,29.3,0,0,1,2.77-7.27,28.43,28.43,0,0,1,4.39-6,26.49,26.49,0,0,1,5.81-4.55A28,28,0,0,1,21,13.52a32.47,32.47,0,0,1,8.16-1,32.49,32.49,0,0,1,6.13.56,30.08,30.08,0,0,1,5.4,1.58,24.27,24.27,0,0,1,4.63,2.45,25.36,25.36,0,0,1,3.83,3.14L45,25.84a3.73,3.73,0,0,1-.93.93,2.6,2.6,0,0,1-1.54.41,3.44,3.44,0,0,1-2-.69c-.66-.46-1.47-1-2.43-1.54a17.34,17.34,0,0,0-3.6-1.54,18.34,18.34,0,0,0-5.48-.69,16.78,16.78,0,0,0-6.76,1.34,14.73,14.73,0,0,0-5.28,3.86,17.84,17.84,0,0,0-3.42,6.22,26.61,26.61,0,0,0-1.22,8.37A25,25,0,0,0,13.74,51a18.62,18.62,0,0,0,3.6,6.25,15.32,15.32,0,0,0,5.34,3.87,16.33,16.33,0,0,0,6.54,1.31,30.38,30.38,0,0,0,3.74-.2,14.84,14.84,0,0,0,3.12-.69,13.62,13.62,0,0,0,2.71-1.25,16.51,16.51,0,0,0,2.57-1.94,4.8,4.8,0,0,1,.93-.61A2.31,2.31,0,0,1,43.34,57.45Z"
            />
            <path
              className={classNames('cls-2', discType)}
              d="M65.56,37.05a18.88,18.88,0,0,1,4.57-5.63,9.49,9.49,0,0,1,6.07-2.06,7.51,7.51,0,0,1,4.62,1.34L80.09,39a1.85,1.85,0,0,1-.63,1.11,1.89,1.89,0,0,1-1.15.31,13.72,13.72,0,0,1-1.86-.18A14.37,14.37,0,0,0,74.18,40a7.66,7.66,0,0,0-2.73.45,6.88,6.88,0,0,0-2.13,1.25,9,9,0,0,0-1.68,2,18.45,18.45,0,0,0-1.39,2.67V71.89H55.08V30.17h6.6a3.41,3.41,0,0,1,2.34.61,3.81,3.81,0,0,1,.93,2.1Z"
            />
            <path
              className={classNames('cls-2', discType)}
              d="M127.52,30.17,105,83.1a4.3,4.3,0,0,1-1.28,1.7,3.92,3.92,0,0,1-2.36.57h-8.3L101,68.53,84.22,30.17H94a3.09,3.09,0,0,1,2.06.61,3.51,3.51,0,0,1,1.06,1.41l7.93,20c.3.79.56,1.58.79,2.37s.45,1.59.67,2.37q.72-2.39,1.65-4.78l7.37-19.91a3,3,0,0,1,1.17-1.45,3.29,3.29,0,0,1,1.86-.57Z"
            />
            <path
              className={classNames('cls-2', discType)}
              d="M156.94,38.75a3.29,3.29,0,0,1-.91,1,2.34,2.34,0,0,1-1.27.3,3.81,3.81,0,0,1-1.66-.4c-.57-.27-1.2-.56-1.91-.87a20.5,20.5,0,0,0-2.4-.87,11,11,0,0,0-3.14-.41,7,7,0,0,0-4.21,1.12,3.48,3.48,0,0,0-1.5,2.93,2.9,2.9,0,0,0,.85,2.11A7.5,7.5,0,0,0,143,45.12a27.88,27.88,0,0,0,3.18,1.18c1.18.36,2.4.76,3.66,1.19a34.27,34.27,0,0,1,3.66,1.5,13,13,0,0,1,3.18,2.12A9.66,9.66,0,0,1,159,54.19a10.08,10.08,0,0,1,.85,4.35,14,14,0,0,1-1.11,5.6,12.28,12.28,0,0,1-3.26,4.44,15.36,15.36,0,0,1-5.34,2.91,23.6,23.6,0,0,1-7.36,1.05,24.16,24.16,0,0,1-4.25-.38,23.17,23.17,0,0,1-4-1.1,24.16,24.16,0,0,1-3.56-1.64,15.66,15.66,0,0,1-2.87-2l2.59-4.17a3,3,0,0,1,2.83-1.53,3.56,3.56,0,0,1,1.86.5c.57.34,1.2.7,1.9,1.09a15.42,15.42,0,0,0,2.49,1.1,11.11,11.11,0,0,0,3.58.5,9.67,9.67,0,0,0,2.8-.36,5.25,5.25,0,0,0,1.9-1,4,4,0,0,0,1.07-1.42,4.05,4.05,0,0,0,.34-1.64,3,3,0,0,0-.87-2.24,7.77,7.77,0,0,0-2.26-1.52,22.84,22.84,0,0,0-3.22-1.18c-1.21-.35-2.45-.74-3.7-1.17a28.89,28.89,0,0,1-3.68-1.54,12.35,12.35,0,0,1-3.2-2.24,10.55,10.55,0,0,1-2.27-3.34,12,12,0,0,1-.87-4.82,12.26,12.26,0,0,1,1-4.91,11.62,11.62,0,0,1,3-4.11,15.14,15.14,0,0,1,5-2.84,21.17,21.17,0,0,1,7-1.05A21.62,21.62,0,0,1,153.5,31a17.54,17.54,0,0,1,6,3.8Z"
            />
            <path
              className={classNames('cls-2', discType)}
              d="M181.22,72.54a14.16,14.16,0,0,1-5-.83,9.77,9.77,0,0,1-5.95-6.09,14.89,14.89,0,0,1-.77-4.94V38.18h-3.92a2.05,2.05,0,0,1-2.15-2.26V31.55l6.68-1.22,2.27-10.8a2.25,2.25,0,0,1,2.47-1.82h5.82v12.7h10.77v7.77H180.65V60a4.24,4.24,0,0,0,.83,2.75,2.92,2.92,0,0,0,2.37,1,4.62,4.62,0,0,0,1.38-.18,5.24,5.24,0,0,0,1-.41l.75-.4a1.5,1.5,0,0,1,.71-.18,1.4,1.4,0,0,1,.81.22,2.49,2.49,0,0,1,.64.75l3.36,5.34a16.51,16.51,0,0,1-5.22,2.75A19.69,19.69,0,0,1,181.22,72.54Z"
            />
            <path
              className={classNames('cls-2', discType)}
              d="M196.44,36a24.87,24.87,0,0,1,8.13-5,27.74,27.74,0,0,1,9.63-1.64,17.49,17.49,0,0,1,6.66,1.21,14.23,14.23,0,0,1,5,3.38A14.83,14.83,0,0,1,229,39.11a19,19,0,0,1,1.1,6.56V71.89H225a5.18,5.18,0,0,1-2.41-.44,3.33,3.33,0,0,1-1.36-1.86l-.89-2.67c-1.05.91-2.07,1.72-3.05,2.42a17.5,17.5,0,0,1-3.06,1.76,15.9,15.9,0,0,1-3.38,1.08,20.57,20.57,0,0,1-4,.36,16.19,16.19,0,0,1-5-.73,10.87,10.87,0,0,1-3.91-2.16,9.66,9.66,0,0,1-2.53-3.58,12.88,12.88,0,0,1-.89-4.94,10.09,10.09,0,0,1,1.18-4.61,11.27,11.27,0,0,1,4-4.19,25.43,25.43,0,0,1,7.61-3.12,53.53,53.53,0,0,1,11.92-1.4V45.67q0-3.93-1.66-5.77a6.13,6.13,0,0,0-4.77-1.84,11.5,11.5,0,0,0-3.85.55,18.52,18.52,0,0,0-2.69,1.17L204,41a4.76,4.76,0,0,1-2.34.55,3.17,3.17,0,0,1-2-.58,5.48,5.48,0,0,1-1.32-1.4Zm22.74,18.49a52.66,52.66,0,0,0-6.8.71,16.49,16.49,0,0,0-4.31,1.32,5.62,5.62,0,0,0-2.26,1.84,4,4,0,0,0-.67,2.24A4,4,0,0,0,206.49,64a6.21,6.21,0,0,0,3.79,1,11,11,0,0,0,4.83-1,14.53,14.53,0,0,0,4.07-3.09Z"
            />
            <path
              className={classNames('cls-2', discType)}
              d="M250.06,11.55V71.89H238.89V11.55Z"
            />
            <polygon
              className={classNames('cls-3', { discType })}
              points="305.91 41.48 307.45 43.57 308.99 41.48 309.54 40.75 310.44 40.5 312.94 39.81 312.31 37.4 312.14 36.77 312.31 36.15 312.94 33.73 310.44 33.05 309.54 32.8 308.99 32.06 307.45 29.98 305.91 32.06 305.36 32.8 304.46 33.05 301.96 33.73 302.59 36.15 302.76 36.77 302.59 37.4 301.96 39.81 304.46 40.5 305.36 40.75 305.91 41.48"
            />
            <polygon
              className={classNames('cls-4', { discType, white })}
              points="301.95 42.49 298.73 41.6 299.58 38.36 292.2 38.36 288.08 47.28 297.86 49.46 301.95 42.49"
            />
            <polygon
              className={classNames('cls-5', { discType, white })}
              points="317.08 23.68 312.78 31.02 316.17 31.94 315.32 35.19 322.44 35.19 326.75 25.85 317.08 23.68"
            />
            <polygon
              className={classNames('cls-4', { discType, white })}
              points="322.63 38.36 315.32 38.36 316.17 41.6 312.89 42.5 316.98 49.46 326.75 47.28 322.63 38.36"
            />
            <polygon
              className={classNames('cls-4', { discType, white })}
              points="310.18 44.33 307.45 48.02 304.69 44.28 300.69 51.09 307.42 57.99 314.15 51.09 310.18 44.33"
            />
            <polygon
              className={classNames('cls-5', { discType, white })}
              points="299.58 35.19 298.73 31.94 302.06 31.03 297.75 23.68 288.08 25.85 292.39 35.19 299.58 35.19"
            />
            <polygon
              className={classNames('cls-5', { discType, white })}
              points="304.76 29.18 307.45 25.53 310.11 29.12 314.22 22.11 307.42 15.13 300.61 22.11 304.76 29.18"
            />
            <g className={classNames('cls-6', { discType })}>
              <polygon
                className={classNames('cls-7', { discType })}
                points="340.77 104.4 342.13 99.43 347.14 100.74 347.14 89.36 333.38 83 330.01 98.08 340.77 104.4"
              />
            </g>
            <g className={classNames('cls-8', { discType })}>
              <polygon
                className={classNames('cls-3', { discType })}
                points="-2.05 69.32 -5.27 71.7 -2.05 74.08 -0.92 74.92 -0.53 76.32 0.52 80.17 4.25 79.19 5.21 78.94 6.17 79.19 9.9 80.17 10.96 76.32 11.34 74.92 12.47 74.08 15.7 71.7 12.47 69.32 11.34 68.48 10.96 67.08 9.9 63.24 6.17 64.21 5.21 64.46 4.25 64.21 0.52 63.24 -0.53 67.08 -0.92 68.48 -2.05 69.32"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="-3.6 63.23 -2.24 58.25 2.77 59.56 2.77 48.18 -10.99 41.82 -14.36 56.91 -3.6 63.23"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="25.4 86.56 14.09 79.92 12.66 85.15 7.65 83.84 7.65 94.82 22.06 101.47 25.4 86.56"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="2.77 95.12 2.77 83.84 -2.24 85.15 -3.63 80.08 -14.36 86.39 -10.99 101.47 2.77 95.12"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="7.65 59.56 12.66 58.25 14.07 63.39 25.4 56.74 22.06 41.82 7.65 48.48 7.65 59.56"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="16.93 67.55 22.55 71.7 17.01 75.8 27.83 82.15 38.59 71.65 27.83 61.15 16.93 67.55"
              />
              <polygon
                className={classNames('cls-3', { discType })}
                points="39.12 28.14 35.9 30.52 39.12 32.9 40.26 33.74 40.64 35.14 41.7 38.99 45.42 38.02 46.39 37.76 47.35 38.02 51.08 38.99 52.13 35.14 52.52 33.74 53.65 32.9 56.87 30.52 53.65 28.14 52.52 27.3 52.13 25.91 51.08 22.06 47.35 23.03 46.39 23.28 45.42 23.03 41.7 22.06 40.64 25.91 40.26 27.3 39.12 28.14"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="37.58 22.05 38.94 17.08 43.95 18.39 43.95 7 30.19 0.65 26.82 15.73 37.58 22.05"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="66.57 45.38 55.27 38.74 53.84 43.97 48.83 42.66 48.83 53.64 63.24 60.3 66.57 45.38"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="43.95 53.94 43.95 42.66 38.94 43.97 37.55 38.91 26.82 45.22 30.19 60.3 43.95 53.94"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="34.73 34.73 29.05 30.52 34.81 26.26 24.3 20.09 13.66 30.47 24.3 40.85 34.73 34.73"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="48.83 18.39 53.84 17.08 55.24 22.21 66.57 15.56 63.24 0.65 48.83 7.3 48.83 18.39"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="58.11 26.37 63.73 30.52 58.19 34.62 69 40.97 79.76 30.47 69 19.97 58.11 26.37"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="107.75 4.21 96.45 -2.43 95.02 2.79 90.01 1.49 90.01 12.46 104.42 19.12 107.75 4.21"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="85.12 12.76 85.12 1.49 80.11 2.79 78.73 -2.27 67.99 4.04 71.37 19.12 85.12 12.76"
              />
              <polygon
                className={classNames('cls-3', { discType })}
                points="54.56 87.25 51.34 89.63 54.56 92.01 55.69 92.85 56.08 94.25 57.13 98.09 60.86 97.12 61.82 96.87 62.78 97.12 66.51 98.09 67.56 94.25 67.95 92.85 69.08 92.01 72.31 89.63 69.08 87.25 67.95 86.41 67.56 85.01 66.51 81.17 62.78 82.14 61.82 82.39 60.86 82.14 57.13 81.17 56.08 85.01 55.69 86.41 54.56 87.25"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="53.01 81.15 54.37 76.18 59.38 77.49 59.38 66.11 45.62 59.75 42.25 74.83 53.01 81.15"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="50.17 93.83 44.48 89.63 50.25 85.37 39.73 79.2 29.1 89.58 39.73 99.96 50.17 93.83"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="64.26 77.49 69.27 76.18 70.68 81.32 82.01 74.67 78.67 59.75 64.26 66.41 64.26 77.49"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="73.54 85.48 79.16 89.63 73.62 93.72 84.44 100.08 95.2 89.58 84.44 79.08 73.54 85.48"
              />
              <polygon
                className={classNames('cls-3', { discType })}
                points="95.73 46.07 92.51 48.45 95.73 50.83 96.87 51.67 97.25 53.07 98.31 56.92 102.03 55.94 103 55.69 103.96 55.94 107.69 56.92 108.74 53.07 109.13 51.67 110.26 50.83 113.48 48.45 110.26 46.07 109.13 45.23 108.74 43.84 107.69 39.99 103.96 40.96 103 41.21 102.03 40.96 98.31 39.99 97.25 43.84 96.87 45.23 95.73 46.07"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="94.19 39.98 95.55 35.01 100.56 36.31 100.56 24.93 86.8 18.57 83.43 33.66 94.19 39.98"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="123.18 63.31 111.88 56.67 110.45 61.9 105.44 60.59 105.44 71.57 119.85 78.23 123.18 63.31"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="100.56 71.87 100.56 60.59 95.55 61.9 94.16 56.84 83.43 63.14 86.8 78.23 100.56 71.87"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="91.34 52.65 85.66 48.45 91.42 44.19 80.91 38.02 70.27 48.4 80.91 58.78 91.34 52.65"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="105.44 36.31 110.45 35.01 111.86 40.14 123.18 33.49 119.85 18.57 105.44 25.23 105.44 36.31"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="114.72 44.3 120.34 48.45 114.8 52.55 125.61 58.9 136.37 48.4 125.61 37.9 114.72 44.3"
              />
              <polygon
                className={classNames('cls-3', { discType })}
                points="136.91 4.9 133.69 7.28 136.91 9.65 138.05 10.49 138.43 11.89 139.49 15.74 143.21 14.77 144.18 14.52 145.14 14.77 148.87 15.74 149.92 11.89 150.3 10.49 151.44 9.65 154.66 7.28 151.44 4.9 150.3 4.06 149.92 2.66 148.87 -1.19 145.14 -0.22 144.18 0.04 143.21 -0.22 139.49 -1.19 138.43 2.66 138.05 4.06 136.91 4.9"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="164.36 22.14 153.06 15.5 151.63 20.72 146.62 19.41 146.62 30.39 161.03 37.05 164.36 22.14"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="141.73 30.69 141.73 19.41 136.72 20.72 135.34 15.66 124.6 21.97 127.98 37.05 141.73 30.69"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="132.52 11.48 126.83 7.28 132.6 3.02 122.09 -3.16 111.45 7.22 122.09 17.6 132.52 11.48"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="155.9 3.12 161.52 7.28 155.97 11.37 166.79 17.72 177.55 7.22 166.79 -3.28 155.9 3.12"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="110.65 95.12 112.01 90.15 117.02 91.45 117.02 80.07 103.26 73.72 99.89 88.8 110.65 95.12"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="121.9 91.45 126.91 90.15 128.31 95.28 139.64 88.63 136.31 73.72 121.9 80.37 121.9 91.45"
              />
              <polygon
                className={classNames('cls-3', { discType })}
                points="153.37 60.04 150.15 62.42 153.37 64.8 154.51 65.64 154.89 67.03 155.94 70.88 159.67 69.91 160.63 69.66 161.6 69.91 165.32 70.88 166.38 67.03 166.76 65.64 167.9 64.8 171.12 62.42 167.9 60.04 166.76 59.2 166.38 57.8 165.32 53.95 161.6 54.92 160.63 55.18 159.67 54.92 155.94 53.95 154.89 57.8 154.51 59.2 153.37 60.04"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="151.82 53.94 153.19 48.97 158.19 50.28 158.19 38.9 144.44 32.54 141.06 47.62 151.82 53.94"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="180.82 77.28 169.52 70.64 168.09 75.86 163.08 74.56 163.08 85.53 177.49 92.19 180.82 77.28"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="158.19 85.83 158.19 74.56 153.19 75.86 151.8 70.8 141.06 77.11 144.44 92.19 158.19 85.83"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="148.98 66.62 143.29 62.42 149.06 58.16 138.55 51.98 127.91 62.36 138.55 72.75 148.98 66.62"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="163.08 50.28 168.09 48.97 169.49 54.11 180.82 47.45 177.49 32.54 163.08 39.2 163.08 50.28"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="172.35 58.26 177.98 62.42 172.43 66.51 183.25 72.86 194.01 62.36 183.25 51.86 172.35 58.26"
              />
              <polygon
                className={classNames('cls-3', { discType })}
                points="194.55 18.86 191.33 21.24 194.55 23.62 195.69 24.46 196.07 25.86 197.12 29.7 200.85 28.73 201.81 28.48 202.78 28.73 206.5 29.7 207.56 25.86 207.94 24.46 209.08 23.62 212.3 21.24 209.08 18.86 207.94 18.02 207.56 16.62 206.5 12.78 202.78 13.75 201.81 14 200.85 13.75 197.12 12.78 196.07 16.62 195.69 18.02 194.55 18.86"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="193 12.76 194.36 7.79 199.37 9.1 199.37 -2.28 185.61 -8.64 182.24 6.44 193 12.76"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="222 36.1 210.69 29.46 209.26 34.69 204.25 33.38 204.25 44.35 218.66 51.01 222 36.1"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="199.37 44.66 199.37 33.38 194.36 34.69 192.98 29.63 182.24 35.93 185.61 51.01 199.37 44.66"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="190.16 25.44 184.47 21.24 190.24 16.98 179.73 10.81 169.09 21.19 179.73 31.57 190.16 25.44"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="204.25 9.1 209.26 7.79 210.67 12.93 222 6.28 218.66 -8.64 204.25 -1.98 204.25 9.1"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="213.53 17.09 219.15 21.24 213.61 25.34 224.43 31.69 235.19 21.19 224.43 10.69 213.53 17.09"
              />
              <polygon
                className={classNames('cls-3', { discType })}
                points="209.98 77.97 206.76 80.35 209.98 82.72 211.12 83.56 211.5 84.96 212.56 88.81 216.28 87.84 217.25 87.59 218.21 87.84 221.94 88.81 222.99 84.96 223.37 83.56 224.51 82.72 227.73 80.35 224.51 77.97 223.37 77.13 222.99 75.73 221.94 71.88 218.21 72.85 217.25 73.11 216.28 72.85 212.56 71.88 211.5 75.73 211.12 77.13 209.98 77.97"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="208.43 71.87 209.79 66.9 214.8 68.21 214.8 56.83 201.05 50.47 197.67 65.55 208.43 71.87"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="205.59 84.55 199.9 80.35 205.67 76.09 195.16 69.91 184.52 80.29 195.16 90.67 205.59 84.55"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="219.69 68.21 224.69 66.9 226.1 72.03 237.43 65.38 234.09 50.47 219.69 57.13 219.69 68.21"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="228.97 76.19 234.59 80.35 229.04 84.44 239.86 90.79 250.62 80.29 239.86 69.79 228.97 76.19"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="266.07 85.83 267.43 80.86 272.44 82.17 272.44 70.79 258.68 64.43 255.31 79.51 266.07 85.83"
              />
              <polygon
                className={classNames('cls-4', { discType, white })}
                points="263.23 98.51 257.54 94.31 263.31 90.05 252.8 83.88 242.16 94.26 252.8 104.64 263.23 98.51"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="277.32 82.17 282.33 80.86 283.74 86 295.07 79.34 291.73 64.43 277.32 71.09 277.32 82.17"
              />
              <polygon
                className={classNames('cls-9', { discType })}
                points="286.6 90.16 292.22 94.31 286.68 98.41 297.5 104.76 308.26 94.26 297.5 83.76 286.6 90.16"
              />
            </g>
          </g>
        </svg>
      </div>
    )
  }
}
